.markdown-content {
  word-break: break-word;
  overflow-wrap: break-word;
}

.markdown-content p {
  margin-bottom: 1em;
}

.markdown-content a {
    color: #3b82f6;
    /* Tailwind's blue-500 */
    text-decoration: underline;
}

.markdown-content a:hover {
    color: #1d4ed8;
    /* Tailwind's blue-700 */
    white-space: pre-wrap;
}